<template>
  <v-container fluid>
    <v-row class="text-center" justify="center" style="min-height: 80vh;">
      <v-col cols="12" md="10" lg="9" align-self="center">
        <v-row justify="center">
          <v-col cols="12">
            <h1 class="text-h2" v-if="$vuetify.breakpoint.mdAndUp">
              Willkommen auf der
              <br />
              <b>"Finde Deinen Sport"</b>
              - Plattform!
            </h1>
            <h1 class="text-h4" v-if="$vuetify.breakpoint.smAndDown">
              Willkommen auf der
              <br />
              <b>"Finde Deinen Sport"</b>
              - Plattform!
            </h1>
          </v-col>
          <v-col cols="12">
            <h2 v-if="$vuetify.breakpoint.mdAndUp">
              Hier hast DU
              <small>(im Alter von 4 bis 18 Jahren)</small>
              die Möglichkeit kostenlos Vereinssport auszuprobieren sowie an
              Events des Netzwerkes teilzunehmen.
            </h2>
            <h3 v-if="$vuetify.breakpoint.smAndDown">
              Hier hast DU
              <small>(im Alter von 4 bis 18 Jahren)</small>
              die Möglichkeit kostenlos Vereinssport auszuprobieren sowie an
              Events des Netzwerkes teilzunehmen.
            </h3>
          </v-col>
          <v-col cols="auto">
            <v-btn x-large rounded color="primary" to="/jetzt-starten">
              Jetzt starten
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn x-large rounded outlined color="primary" @click="$vuetify.goTo('#mehr-erfahren', {duration: 700, offset: 0, easing: 'linear'})">
              Mehr erfahren
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center" class="primary pa-5" id="mehr-erfahren">
      <v-col cols="10" md="4" lg="3" order="2" order-md="1">
        <v-img src="/img/vereine/kommune.png" contain />
      </v-col>
      <v-col cols="12" md="8" lg="9" class="text-center white--text">
        <h3>
          Du hast Lust etwas Neues auszuprobieren, neue Sportarten und Sportvereine in Greven kennenzulernen? Du bist du unter 18 Jahre alt und kommst aus Greven? Dann bist du hier genau richtig!
          <br>
          <br>Mit unserer Sportflatrate hast du die Möglichkeit, ganz unverbindlich und ohne Kosten viele unterschiedlichen Sportarten kennenzulernen. Egal ob ein einmaliges Schnuppertraining, ein mehrtägiger Kurs oder verschiedene Sportaktionen: alles, was du dafür tun musst, ist dich anzumelden.
          <br>
          <br>Also, los geht’s! Melde dich direkt an und finde Deinen Sport!
        </h3>
      </v-col>
    </v-row>
    <v-row justify="center" class="secondary pa-5">
      <v-col cols="10" md="4" align-self="center" class="text-center">
        <v-img src="/img/vereine/ssv.png" width="75%" class="mx-auto" contain />
      </v-col>
      <v-col cols="10" md="4" align-self="center" class="text-center">
        <v-divider v-if="$vuetify.breakpoint.smAndDown" class="mb-5"></v-divider>
        <v-img src="/img/vereine/greven.png" width="75%" class="mx-auto" contain />
      </v-col>
      <v-col cols="10" md="4" align-self="center" class="text-center">
        <v-divider v-if="$vuetify.breakpoint.smAndDown" class="mb-5"></v-divider>
        <v-img src="/img/vereine/ksb.png" width="75%" class="mx-auto" contain />
      </v-col>
      <v-col cols="12" md="10" class="text-center primary--text">
        <v-divider v-if="$vuetify.breakpoint.smAndDown" class="my-3"></v-divider>
        <h2>Beteiligte Vereine & Institutionen:</h2>
      </v-col>
      <v-col cols="11" md="4" class="text-center" align-self="center" v-for="(verein, index) in vereine.all" :key="'Verein'+index" style="height: 100% !important;">
        <v-card class="rounded-xl pa-3 text-center" :color="verein.farben.primary" :style="`color: ${verein.farben.secondary};`">
          <v-row justify="center">
            <v-col cols="auto">
              <v-img :src="verein.logo" width="120px" class="mx-auto" contain />
            </v-col>
            <v-col cols="12" class="text-center">
              <h3>{{verein.name}}</h3>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import router from '../router'

export default {
  name: 'Startseite',

  data: () => ({}),
  computed: {
    ...mapGetters({
      user: 'user',
      vereine: 'vereine',
      db: 'db',
      sportarten: 'sportarten',
      sportangebote: 'sportangebote',
    }),
  },
  mounted(){
    if(this.user.data){
      if(this.user.data.admin){
        router.push('/admin')
      }
      else if(this.user.data.verein){
        router.push('/verein')
      }
      else {
        router.push('/user')
      }
    }
  }
}
</script>
